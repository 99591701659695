import React, {useEffect, useMemo, useState} from 'react';
import {RiseInterstitialProps} from 'screens/RiseSurvey/components/RiseInterstitialTemplate/RiseInterstitialBase';
import {addDays, format} from 'date-fns';
import {surveyInstance} from 'screens/RiseSurvey/RiseSurvey';
import Analytics from 'utils/Analytics';
import RiseSurveyInterstitialControls from '../../components/RiseSurveyControls/RiseSurveyInterstitialControls';
import styles from './PlanTeaser.module.css';
import ScaleBar from './components/ScaleBar/ScaleBar';
import {circadianAlignmentMap, daytimeEnergyMap, morningWakefulnessMap, sleepQualityMap} from './planTeaserMapping';

const delta = (value: {start: number; end: number}) => {
  return Math.abs(value.start - value.end);
};
const PlanTeaser: React.FC<RiseInterstitialProps> = () => {
  const [showSleepQuality, setShowSleepQuality] = useState(false);
  const [showMorningWakefulness, setShowMorningWakefulness] = useState(false);
  const [showDaytimeEnergy, setShowDaytimeEnergy] = useState(false);
  const [showCircadianAlignment, setShowCircadianAlignment] = useState(false);
  const date = format(addDays(new Date(), 30), 'MMM do');
  const sleepQuality = useMemo(() => sleepQualityMap(surveyInstance.getValue('current_quality_v2')), []);
  const morningWakefulness = useMemo(() => morningWakefulnessMap(surveyInstance.getValue('wake_up')), []);
  const daytimeEnergy = useMemo(() => daytimeEnergyMap(surveyInstance.getValue('daytime_energy')), []);
  const circadianAlignment = useMemo(
    () =>
      circadianAlignmentMap(surveyInstance.getValue('sleep_patterns'), surveyInstance.getValue('sleep_consistency')),
    [],
  );
  const showCritical = {
    sleepQuality: [1, 2].includes(sleepQuality.level),
    morningWakefulness: [1, 2].includes(morningWakefulness.level),
    daytimeEnergy: [1, 2].includes(daytimeEnergy.level),
    circadianAlignment: [1, 2].includes(circadianAlignment.level),
  };

  useEffect(() => {
    const timing = 1200;
    const t1 = setTimeout(() => {
      setShowSleepQuality(true);
    }, timing);
    const t2 = setTimeout(() => {
      setShowMorningWakefulness(true);
    }, timing * 2);
    const t3 = setTimeout(() => {
      setShowDaytimeEnergy(true);
    }, timing * 3);
    const t4 = setTimeout(() => {
      setShowCircadianAlignment(true);
    }, timing * 4);
    return () => {
      clearTimeout(t1);
      clearTimeout(t2);
      clearTimeout(t3);
      clearTimeout(t4);
    };
  }, []);

  useEffect(() => {
    Analytics.track('Personalized Plan Shown', {
      sleepQuality: sleepQuality.level,
      morningWakefulness: morningWakefulness.level,
      daytimeEnergy: daytimeEnergy.level,
      circadianAlignment: circadianAlignment.level,
      sum: sleepQuality.level + morningWakefulness.level + daytimeEnergy.level + circadianAlignment.level,
    });
  }, [sleepQuality, morningWakefulness, daytimeEnergy, circadianAlignment]);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Expected improvements by</div>
        <div className={styles.date}>{date}</div>
        <div className={styles.subtitle}>
          Projected results based on your responses and data from millions of RISE users
        </div>
      </div>
      <div className={styles.barContainer}>
        {showSleepQuality ? (
          <ScaleBar
            title="Sleep quality"
            currentValue={sleepQuality.start}
            futureValue={sleepQuality.end}
            delta={delta(sleepQuality)}
            showCritical={showCritical.sleepQuality}
          />
        ) : null}
        {showMorningWakefulness ? (
          <ScaleBar
            title="Morning wakefulness"
            currentValue={morningWakefulness.start}
            futureValue={morningWakefulness.end}
            delta={delta(morningWakefulness)}
            showCritical={showCritical.morningWakefulness}
          />
        ) : null}
        {showDaytimeEnergy ? (
          <ScaleBar
            title="Daytime energy"
            currentValue={daytimeEnergy.start}
            futureValue={daytimeEnergy.end}
            delta={delta(daytimeEnergy)}
            showCritical={showCritical.daytimeEnergy}
          />
        ) : null}
        {showCircadianAlignment ? (
          <ScaleBar
            title="Circadian alignment"
            currentValue={circadianAlignment.start}
            futureValue={circadianAlignment.end}
            delta={delta(circadianAlignment)}
            showCritical={showCritical.circadianAlignment}
          />
        ) : null}
      </div>
      <RiseSurveyInterstitialControls />
    </div>
  );
};
export default PlanTeaser;
