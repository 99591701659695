import {priceWithCurrency} from 'utils/locale';
import {PlanInfo} from 'utils/stripePlans';
import styles from './YouGetFeatures.module.css';

export const YouGetFeatures = ({selectedPlan}: {selectedPlan: PlanInfo}) => {
  return (
    <section className={styles.youGetFeatures}>
      <ul>
        <li>
          You get a {selectedPlan.percentOff}% introductory offer discount –{' '}
          {priceWithCurrency(Math.round(selectedPlan.price - selectedPlan.introPrice), selectedPlan, 0)}.
        </li>
        <li>
          Your introductory period will last for {selectedPlan.introDuration} for{' '}
          {priceWithCurrency(selectedPlan.introPrice, selectedPlan)}.
        </li>
        <li>
          You can cancel anytime before the end on introductory period, and you will not be charged for membership.
        </li>
        <li>
          After the end of introductory period Rise will automatically continue your membership and charge{' '}
          {priceWithCurrency(selectedPlan.price, selectedPlan)} / {selectedPlan.duration} until you cancel.
        </li>
        <li>You can cancel any time.</li>
      </ul>
    </section>
  );
};
