import {ReactNode} from 'react';
import styles from './PlanOption.module.css';
import {PlanInfo} from '../../../../../utils/stripePlans';
import {currencySymbolAtStart, priceWithCurrency} from '../../../../../utils/locale';

type PlanOptionProps = {
  index: number;
  selected: boolean;
  offerDetails?: string | ReactNode;
  currencySymbol: string;
  pricePerDay: number;
  planPrice?: number;
  planIntroPrice: number;
  badgeText?: string | null;
  planTitle: string;
  onClick: () => void;
  plan: PlanInfo;
};

export const PlanOption = ({
  selected,
  offerDetails,
  currencySymbol,
  pricePerDay,
  planPrice,
  planIntroPrice,
  badgeText,
  planTitle,
  onClick,
  plan,
}: PlanOptionProps) => {
  const splitPrice = pricePerDay.toFixed(2).toString().split('.');
  const atStart = currencySymbolAtStart(plan.introPrice, plan);

  return (
    <div className={`${styles.gradientBorderBox} ${selected ? styles.selected : ''}`}>
      <div className={`${styles.container}`} onClick={onClick}>
        {badgeText ? <div className={styles.badge}>{badgeText}</div> : null}
        <div className={styles.pricingContainer}>
          <div className={styles.planContainer}>
            <div className={styles.planLabel}>{planTitle}</div>
            <div className={styles.priceContainer}>
              {planPrice ? <span className={styles.price}>{priceWithCurrency(planPrice, plan)}</span> : null}→
              <span className={styles.priceIntro}>{priceWithCurrency(planIntroPrice, plan)}</span>
            </div>
          </div>
          <div className={styles.perDayContainer} style={{marginTop: badgeText ? 8 : 0}}>
            {atStart ? <div className={styles.currency}>{currencySymbol}</div> : null}
            <div className={styles.dollars}>{splitPrice[0]}</div>
            <div>
              <div className={styles.cents}>
                {splitPrice[1]}
                {!atStart ? ` ${currencySymbol}` : null}
              </div>
              <div className={styles.perDayLabel}>per day</div>
            </div>
          </div>
        </div>

        {offerDetails ? <div className={styles.offerDetails}>{offerDetails}</div> : null}
      </div>
    </div>
  );
};
