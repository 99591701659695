import star from './assets/star.svg';
import styles from './Testimonials.module.css';

export const Testimonials = () => (
  <section className={styles.container} style={{marginTop: '24px'}}>
    <h3 className={styles.title}>Transforming Sleep, Boosting Energy</h3>
    <div className={styles.testimonial}>
      <div className={styles.stars}>
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
      </div>
      <cite> Jamie T.</cite>
      "Since I started using Rise, my mornings have changed completely. I used to struggle with falling asleep and felt
      like I needed a crane just to get out of bed. Now, I wake up feeling genuinely refreshed. The app's personalized
      tips, especially for someone in their 30s struggling with sleep, have been a game-changer. It's like having a
      sleep coach in my pocket."
    </div>
    <div className={styles.testimonial}>
      <div className={styles.stars}>
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
      </div>
      <cite>Chris P.</cite>
      "Rise has helped me understand my sleep needs and how to meet them. I've always had a hard time falling asleep,
      but the app's recommendations have made a significant difference. I appreciate how it tailors advice based on my
      age group and my goal to wake up feeling refreshed. Plus, tracking my sleep debt has been eye-opening!"
    </div>
    <div className={styles.testimonial}>
      <div className={styles.stars}>
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
      </div>
      <cite>Morgan L.</cite>
      "I was skeptical at first, but Rise has transformed my sleep habits. The circadian rhythm optimization is
      fascinating and effective. I've adjusted my bedtime and wake-up time slightly, following the app's guidance, and
      even my weekends have improved. The fact that it considers my dinner habits and bedroom environment makes the
      advice feel very personalized."
    </div>
  </section>
);
