import {PlanInfo, SupportedCountryCode} from 'utils/stripePlans';
import {BlackButton} from '../Buttons/BlackButton';
import {getUserCountry, priceWithCurrency} from 'utils/locale';
import right_arrow from './assets/right_arrow.svg';
import thumbs_up from './assets/thumbs_up.svg';
import styles from './PlanSelection.module.css';
const PlanOption = ({
  index,
  selectedPlan,
  plan,
  onPriceSelection,
}: {
  index: number;
  selectedPlan: PlanInfo;
  plan: PlanInfo;
  onPriceSelection: (plan: PlanInfo) => void;
}) => {
  const selected = selectedPlan.id === plan.id;

  return (
    <div
      className={`${styles.planSelection} ${selected ? styles.selected : ''}`}
      onClick={() => onPriceSelection(plan)}
    >
      <div className={`${styles.planSelectionHeader} ${selected ? styles.selected : styles.unselected}`}>
        {index === 1 ? 'LITE VERSION' : null}
        {index === 2 ? (
          <>
            <div className={styles.planSelectionHeaderLeft}>
              <span>MOST POPULAR</span>
              <img src={thumbs_up} alt="Thumbs Up" />
            </div>
            <div>
              <div className={styles.planSelectionHeaderRight}>-{`${plan.percentOff}`}%</div>
            </div>
          </>
        ) : null}
        {index === 3 ? (
          <>
            <div className={styles.planSelectionHeaderLeft}>
              <span>BEST PRICE</span>
            </div>
            <div>
              <div className={styles.planSelectionHeaderRight}>-{`${plan.percentOff}`}%</div>
            </div>
          </>
        ) : null}
      </div>
      <div className={styles.planSelectionPrice}>
        <div className={styles.planSelectionHeaderPriceInfo}>
          <div className={styles.planSelectionDuration}>{plan.frequency}</div>
          <div className={styles.strikethroughContainer}>
            <s>{priceWithCurrency(plan.price, plan)}</s>
            <img src={right_arrow} alt="Right Arrow" />
            <span>{priceWithCurrency(plan.introPrice, plan)}</span>
          </div>
        </div>
        <div className={styles.planSelectionPerDay}>
          <div className={styles.planSelectionDollar}>
            {priceWithCurrency(plan.perDay, plan, plan.perDay === 1 ? 0 : 2)}
          </div>
          <div className={styles.planSelectionDayInfo}>per day</div>
        </div>
      </div>
    </div>
  );
};

export const PlanSelection = ({
  selectedPlan,
  onPriceSelection,
  onButtonClick,
  weekPlan,
  monthPlan,
  quarterPlan,
}: {
  selectedPlan: PlanInfo;
  onPriceSelection: (plan: PlanInfo) => void;
  onButtonClick: () => void;
  weekPlan: Record<SupportedCountryCode, PlanInfo>;
  monthPlan: Record<SupportedCountryCode, PlanInfo>;
  quarterPlan: Record<SupportedCountryCode, PlanInfo>;
}) => {
  const {countryCode: locale} = getUserCountry();
  const weeklyPlan = weekPlan[locale];
  const monthlyPlan = monthPlan[locale];
  const quarterlyPlan = quarterPlan[locale];

  return (
    <section className="">
      <div className={styles.planSelectionContainer}>
        <PlanOption index={1} selectedPlan={selectedPlan} plan={weeklyPlan} onPriceSelection={onPriceSelection} />
        <PlanOption index={2} selectedPlan={selectedPlan} plan={monthlyPlan} onPriceSelection={onPriceSelection} />
        <PlanOption index={3} selectedPlan={selectedPlan} plan={quarterlyPlan} onPriceSelection={onPriceSelection} />
        <div className={styles.planSelectionButton}>
          <BlackButton title="Get my plan" onClick={onButtonClick} />
          <p className={styles.planSelectionGuarantee}>100% Money-back guarantee</p>
        </div>
      </div>
    </section>
  );
};
