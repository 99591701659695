import * as Sentry from '@sentry/react';
import {PlanInfo, SupportedCountryCode} from './stripePlans';
import {getCountryByTimezone} from './timezones';

type SupportedEuroCountry =
  | 'AT' // 'Austria' |
  | 'BE' // 'Belgium' |
  | 'FI' // 'Finland' |
  | 'FR' // 'France' |
  | 'DE' // 'Germany' |
  | 'IE' // 'Ireland' |
  | 'IT' // 'Italy' |
  | 'LU' // 'Luxembourg' |
  | 'NL' // 'Netherlands' |
  | 'PT' // 'Portugal' |
  | 'ES' // 'Spain' |
  | 'AD' // 'Andorra' |
  | 'HR' // 'Croatia' |
  | 'CY' // 'Cyprus' |
  | 'EE' // 'Estonia' |
  | 'GR' // 'Greece' |
  | 'LV' // 'Latvia' |
  | 'LT' // 'Lithuania' |
  | 'MT' // 'Malta' |
  | 'MC' // 'Monaco' |
  | 'SK' // 'Slovakia' |
  | 'SI'; // 'Slovenia',

const euroCountryToCommonLang: Record<SupportedEuroCountry, string> = {
  AT: 'de-at',
  BE: 'nl-be',
  FI: 'fi',
  FR: 'fr',
  DE: 'de',
  IE: 'en-ie',
  IT: 'it',
  LU: 'lb', // this is nuts
  NL: 'nl',
  PT: 'pt',
  ES: 'es',
  AD: 'ca-AD',
  HR: 'hr',
  CY: 'el-CY',
  EE: 'et', // come on
  GR: 'el',
  LV: 'lv',
  LT: 'lt',
  MT: 'mt',
  MC: 'fr',
  SK: 'sk',
  SI: 'sl', // how confusing is this???
};

const supportedEuroCountries = Object.keys(euroCountryToCommonLang);

export const getUserCountry = (): {
  countryCode: SupportedCountryCode;
  userLanguages: readonly string[];
  timezone: string;
  timezoneStrategy: boolean;
  countryByTimezone: string;
  localeToFormatCurrency: string;
} => {
  // For QA Admin Tools
  const overrideCountryCode = localStorage.getItem('qa_countryCode');
  if (overrideCountryCode) {
    return {
      countryCode: overrideCountryCode as SupportedCountryCode,
      userLanguages: [],
      timezone: '',
      timezoneStrategy: false,
      countryByTimezone: '',
      localeToFormatCurrency: '',
    };
  }

  let userLanguages: readonly string[] = ['en-US'];
  try {
    userLanguages = window.navigator.languages;
  } catch {}

  let countryByTimeZone: string | null = null;
  let timezone = 'unresolved';
  let localeToFormatCurrency = 'unresolved';

  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    countryByTimeZone = getCountryByTimezone(timezone);
    localeToFormatCurrency = localeToRenderCurrencyIn(countryByTimeZone);
  } catch {}

  if (countryByTimeZone) {
    if (['US', 'CA', 'GB', 'AU'].includes(countryByTimeZone)) {
      return {
        countryCode: countryByTimeZone as SupportedCountryCode,
        userLanguages,
        timezone,
        timezoneStrategy: true,
        countryByTimezone: countryByTimeZone,
        localeToFormatCurrency,
      };
    }
    if (supportedEuroCountries.includes(countryByTimeZone)) {
      return {
        countryCode: 'EU',
        userLanguages,
        timezone,
        timezoneStrategy: true,
        countryByTimezone: countryByTimeZone,
        localeToFormatCurrency,
      };
    }
  }

  try {
    let country: SupportedCountryCode = 'US';

    for (const locale of userLanguages) {
      if (locale.includes('-')) {
        const userCountry = locale.split('-')[1];
        if (['US', 'GB', 'CA', 'AU'].includes(userCountry)) {
          country = userCountry as SupportedCountryCode;
          break;
        }
      }
      // Support European users with language codes such as 'nl' or 'fr-BE'
      const [lang, region] = locale.split('-');
      if (
        (lang && supportedEuroCountries.includes(lang.toUpperCase())) ||
        (region && supportedEuroCountries.includes(region.toUpperCase()))
      ) {
        country = 'EU';
        break;
      }
    }

    return {
      countryCode: country,
      userLanguages,
      timezone,
      timezoneStrategy: false,
      countryByTimezone: String(countryByTimeZone),
      localeToFormatCurrency,
    };
  } catch (e) {
    Sentry.captureException(e);
    return {
      countryCode: 'US',
      userLanguages,
      timezone,
      timezoneStrategy: false,
      countryByTimezone: String(countryByTimeZone),
      localeToFormatCurrency,
    };
  }
};

type PayPalCurrency = 'USD' | 'CAD' | 'GBP' | 'AUD' | 'EUR';
export const getPayPalCurrency = (): PayPalCurrency => {
  const {countryCode} = getUserCountry();

  const countryCurrencyMapping: Record<SupportedCountryCode, PayPalCurrency> = {
    US: 'USD',
    CA: 'CAD',
    GB: 'GBP',
    AU: 'AUD',
    EU: 'EUR',
  };

  return countryCurrencyMapping[countryCode] ?? 'USD';
};

const localeToRenderCurrencyIn = (countryByTimezone: string | null): string => {
  let userLanguages: readonly string[] = ['en-US'];
  try {
    userLanguages = window.navigator.languages;
  } catch {}

  if (countryByTimezone && ['US', 'CA', 'AU', 'GB'].includes(countryByTimezone)) {
    try {
      if (countryByTimezone === 'CA' && Intl.DateTimeFormat().resolvedOptions().timeZone === 'America/Montreal') {
        return 'fr-CA';
      }
    } catch {}
    if (countryByTimezone === 'CA' && userLanguages[0] === 'fr-CA') {
      return 'fr-CA';
    } else {
      return countryByTimezone;
    }
  } else if (countryByTimezone && supportedEuroCountries.includes(countryByTimezone)) {
    return euroCountryToCommonLang[countryByTimezone as SupportedEuroCountry];
  }

  return userLanguages[0];
};

export const priceWithCurrency = (value: number, plan: PlanInfo, minimumFractionDigits = 2) => {
  let localeToRender = 'en-US';

  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const countryByTimezone = getCountryByTimezone(timezone);
    localeToRender = localeToRenderCurrencyIn(countryByTimezone);
  } catch {}

  if (['cad', 'aud'].includes(plan.currency)) {
    // we want Canadian, Australian users to know immediately that their checkout is not in USD
    // shows 'C$1.99 / A$1.99'. Otherwise, they'd just see '$1.99'
    if (localeToRender === 'fr-CA') {
      return `${value.toFixed(minimumFractionDigits)} ${plan.label}`;
    }
    return `${plan.label}${value.toFixed(minimumFractionDigits)}`;
  }

  // netherlands doesn't format as expected, has a space between euro and amount - a known issue with Intl.NumberFormat
  // also, do we care if this is a supported locale or not?
  return new Intl.NumberFormat(localeToRender, {
    style: 'currency',
    currency: plan.currency,
    minimumFractionDigits,
  }).format(value);
};

export const currencySymbolAtStart = (value: number, plan: PlanInfo, minimumFractionDigits = 2) => {
  const currencySymbol = plan.label;

  const formattedPrice = priceWithCurrency(value, plan, minimumFractionDigits);

  const locationOfSymbol = formattedPrice.indexOf(currencySymbol);

  return locationOfSymbol === 0;
};
