import React, {useEffect} from 'react';
import 'screens/RiseSurvey/components/RiseInterstitialTemplate/interstitialBase.css';
import {RiseInterstitialProps} from 'screens/RiseSurvey/components/RiseInterstitialTemplate/RiseInterstitialBase';
import {surveyInstance} from 'screens/RiseSurvey/RiseSurvey';
import styles from './AnalyzeInterstitialV2.module.css';
import AnimatedBar from './components/AnimatedBar';
import {AnimatedCounter} from './components/AnimatedCounter';
import SocialProof from './components/SocialProof';
const duration = 2500;
type BarRowProps = {
  title: string;
  duration: number;
  delay: number;
};
const BarRow = ({title, duration, delay}: BarRowProps) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '35px',
          fontSize: '15px',
          color: 'black',
        }}
      >
        <div className={styles.rowTitle}>{title}</div>
        <div style={{fontWeight: 'bold'}}>
          <AnimatedCounter duration={duration} delay={delay} />
        </div>
      </div>
      <AnimatedBar duration={duration} delay={delay} />
    </div>
  );
};

const AnalyzeInterstitial: React.FC<RiseInterstitialProps> = () => {
  const personName = surveyInstance.getValue('person_name');
  const goalPhrase = surveyInstance.getVariable('profile_goal_phrase').toLowerCase();

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        surveyInstance.nextPage();
      },
      duration * 3 - 300,
    );
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div data-testid="analyze-interstitial">
      <div className="interstitial">
        <div>
          <div className={styles.title}>
            OK {personName}, we’re crafting your plan to {goalPhrase}
          </div>
          <div style={{marginTop: '24px'}}>
            <BarRow title="Analyzing answers" duration={duration} delay={0} />
          </div>
          <div style={{marginTop: '24px'}}>
            <BarRow title="Building sleep profile" duration={duration} delay={duration} />
          </div>
          <div style={{marginTop: '24px'}}>
            <BarRow title="Creating personalized plan" duration={duration} delay={duration * 2} />
          </div>
          <div style={{marginTop: '48px'}}>
            <SocialProof />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyzeInterstitial;
