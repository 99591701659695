import {Duration, intervalToDuration} from 'date-fns';
import stopwatch from './stopwatch.svg';
import styles from './OfferExpirationTimer.module.css';
export const OfferExpirationTimer = ({
  timeLeft,
  timedOut,
  formatDuration,
}: {
  timeLeft: number;
  timedOut: boolean;
  formatDuration: (duration: Duration) => string;
}) => {
  const duration = intervalToDuration({start: 0, end: timeLeft});
  return (
    <div className={styles.container}>
      <img src={stopwatch} alt="Stopwatch" className={styles.image} />
      <div>{timedOut ? 'We saved your discount!' : `This offer expires in ${formatDuration(duration)}`}</div>
    </div>
  );
};
