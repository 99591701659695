import map from './assets/map.svg';
import community from './assets/community-map.webp';
import styles from './CommunityInvitation.module.css';

export const CommunityInvitation = () => (
  <section className={styles.container}>
    <h2>Join Rise community</h2>
    <p style={{fontSize: '17px'}}>
      More than <strong>5 million+</strong> happy users!
    </p>
    <div className={styles.mapContainer}>
      <img src={map} alt="Community" className={styles.image} />
      <div className={styles.peoplesHeads}>
        <img src={community} alt="Community" className={styles.image} />
      </div>
    </div>
  </section>
);
