import {DiscountButton} from '../Buttons/DiscountButton';
import stopwatch_orange from './assets/stopwatch-orange.svg';
import styles from './SavedDiscountBanner.module.css';

export const SavedDiscountBanner = ({onButtonClick}: {onButtonClick: () => void}) => (
  <section className={styles.container}>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '8px', paddingRight: '12px'}}>
      <img src={stopwatch_orange} alt="Stopwatch" />
      <div>We saved your discount!</div>
    </div>
    <div>
      <DiscountButton title="Get discount" onClick={onButtonClick} />
    </div>
  </section>
);
