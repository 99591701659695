import {Duration} from 'date-fns';
import styles from './PricingOptions.module.css';
import {OfferExpirationTimer} from '../OfferExpirationTimer/OfferExpirationTimer';

export const PricingOptions = ({
  timeLeft,
  timedOut,
  formatDuration,
}: {
  timeLeft: number;
  timedOut: boolean;
  formatDuration: (duration: Duration) => string;
}) => (
  <section className={styles.container}>
    <h3>Choose your plan</h3>
    <OfferExpirationTimer timeLeft={timeLeft} timedOut={timedOut} formatDuration={formatDuration} />
  </section>
);
