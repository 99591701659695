import React, {useEffect, useState} from 'react';
import ButtonSolid from 'components/Buttons/ButtonSolid';
import Analytics from 'utils/Analytics';
import {PageModel} from 'survey-core';
import {surveyInstance} from '../../RiseSurvey';

const getNextDelayMs = (page: PageModel) => {
  //@ts-expect-error We need to access the custom `riseProperties` property, and this is the only way I know how.
  const {delayOnNext} = page?.jsonObj?.riseProperties ?? {};
  return delayOnNext;
};

const allQuestionsAnswered = (page: PageModel) => {
  return page.questions.every((question: any) => question.value !== undefined && question.value?.length > 0);
};

export const NextButton: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const page = surveyInstance.currentPage;
  const nextDelayMs = getNextDelayMs(page);

  useEffect(() => {
    // When the screen loads, check if all questions are answered
    // if so, enable the button
    if (allQuestionsAnswered(page)) {
      setDisabled(false);
    }

    const handleValueChanged = (sender: any) => {
      setDisabled(!allQuestionsAnswered(sender.currentPage));
    };
    // when going back and there is a value select, enable the button
    surveyInstance.onCurrentPageChanged.add(handleValueChanged);
    // when a value is changed, enable the button
    surveyInstance.onValueChanged.add(handleValueChanged);
    return () => {
      surveyInstance.onCurrentPageChanged.remove(handleValueChanged);
      surveyInstance.onValueChanged.remove(handleValueChanged);
    };
  }, [page]);

  return (
    <ButtonSolid
      title={'Next'}
      loading={loading}
      disabled={disabled}
      onClick={() => {
        const page = surveyInstance.currentPage;
        setDisabled(true);
        Analytics.track('Next clicked', {
          survey_name: surveyInstance.title,
          question_title: page.questions[0].title,
          question_name: page.questions[0].name,
          question_value: page.questions[0].value,
          pageNumber: surveyInstance.currentPageNo,
        });
        if (nextDelayMs > 0) {
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            surveyInstance?.nextPage();
          }, nextDelayMs);
        } else {
          surveyInstance?.nextPage();
        }
      }}
    />
  );
};
