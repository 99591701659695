import card_amex from './assets/card_amex.webp';
import card_discover from './assets/card_discover.webp';
import card_mastercard from './assets/card_master.webp';
import card_visa from './assets/card_visa.webp';
import lock from './assets/lock.svg';

import styles from './SafeCheckout.module.css';

export const SafeCheckout = () => (
  <section className={styles.container}>
    <h3>Guaranteed safe checkout</h3>
    <div className={styles.cards}>
      <div className={styles.card}>
        <img src={card_visa} alt="Visa" className={styles.image} />
      </div>
      <div className={styles.card}>
        <img src={card_mastercard} alt="Mastercard" className={styles.image} />
      </div>
      <div className={styles.card}>
        <img src={card_amex} alt="Amex" className={styles.image} />
      </div>
      <div className={styles.card}>
        <img src={card_discover} alt="Discover" className={styles.image} />
      </div>
    </div>
    <div className={styles.subtitle}>
      <img src={lock} alt="Lock" />
      <p>All transactions are secure and encrypted</p>
    </div>
  </section>
);
