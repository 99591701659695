import styles from './MoneyBackGuarantee.module.css';

export const MoneyBackGuarantee = () => (
  <section className={styles.container}>
    <h3>100% money back guarantee</h3>
    <p>
      Elevate your well-being with Rise App—a comprehensive tracker designed to enhance your sleep quality and daily
      energy levels. We're confident in the transformative impact our app can have on your overall wellness. If, within
      30 days of purchase, you find that Rise doesn't meet your expectations, we're pleased to offer a full refund. Your
      satisfaction in achieving better sleep and heightened energy is our priority."
    </p>
  </section>
);
