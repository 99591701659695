import img_avatar_1 from 'images/avatar-1.png';
import img_avatar_2 from 'images/avatar-2.png';
import img_avatar_3 from 'images/avatar-3.png';
import img_avatar_4 from 'images/avatar-4.png';
import img_female_hero from 'images/female-user.jpg';
import img_graph_multiple from 'images/graph-multiple.png';
import img_male_hero from 'images/male-user.jpg';
import img_wearables_no from 'images/mobile-phone.png';
import img_wearables_yes from 'images/mobile-watch.png';
import img_nfl_nba from 'images/nfl_nba.png';
import img_rise_twinkles from 'images/rise-logo-with-twinkles.png';
import img_sleep_debt_accumulation from 'images/sleep-debt-accumlation-chart.png';
import img_sleep_debt_high from 'images/sleep-debt-high.png';
import img_sleep_debt_low from 'images/sleep-debt-low.png';
import img_sleep_debt_moderate from 'images/sleep-debt-moderate.png';
import img_sleep_debt_reduction_chart from 'images/sleep-debt-reduction-chart.png';
import img_twinkles from 'images/twinkles.svg';
import img_sleep_beliefs_quiz_illustration from 'images/sleep-beliefs-quiz-illustration.svg';
import RiseInterstitialTemplate from 'screens/RiseSurvey/components/RiseInterstitialTemplate/RiseInterstitialTemplate';
import AnalyzeInterstitialV2 from 'screens/RiseSurvey/interstitials/AnalyzeInterstitialV2/AnalyzeInterstitialV2';
import ProgressChart from 'screens/RiseSurvey/interstitials/ProgressInterstitial/ProgressChart';
import SleepProfileInterstitial from 'screens/RiseSurvey/interstitials/SleepProfileInterstitial/SleepProfileInterstitial';
import {surveyInstance} from 'screens/RiseSurvey/RiseSurvey';
import {survey_config_base} from 'screens/RiseSurvey/utils/__survey_config_base';
import {interstitial} from 'screens/RiseSurvey/utils/interstitial';
import {radioPage, textPage} from 'screens/RiseSurvey/utils/page';
import {randomize} from 'screens/RiseSurvey/utils/randomize';
import {switchExpression} from 'screens/RiseSurvey/utils/switchExpression';
import {RiseSurveyConfig} from 'types';
import {getSleepDebtZoneFromMidpointOffsets} from 'utils/getSleepDebtZoneFromMidpointOffsets';
import {pickSurveyItem_UNSAFE} from 'utils/pickSurveyItem_UNSAFE';
import {useEffect} from 'react';
import Analytics from 'utils/Analytics';
import AccountCreationInterstitial from 'screens/RiseSurvey/interstitials/AccountCreationInterstitial/AccountCreationInterstitial';

const InfoBox = ({title, body}: {title: string; body: string}) => {
  return (
    <div style={{backgroundColor: 'rgba(142, 88, 255, 0.2)', borderRadius: '16px', padding: '15px', textAlign: 'left'}}>
      <div style={{fontWeight: 'bold', fontSize: '16px', marginBottom: '8px'}}>{title}</div>
      <div style={{fontSize: '16px', paddingTop: '8px'}}>{body}</div>
    </div>
  );
};

const ResultInterstitial = () => {
  const quizQuestions = surveyInstance.getQuestionsByNames([
    'sleep_quiz_8_hours_sleep_question',
    'sleep_quiz_resting_question',
    'sleep_quiz_try_harder_question',
    'sleep_quiz_more_sleep_question',
    'sleep_quiz_weekend_sleep_question',
    'sleep_quiz_older_adults_sleep_question',
    'sleep_quiz_alcohol_sleep_question',
    'sleep_quiz_caffeine_sleep_question',
  ]);
  const answers = quizQuestions.map(question => question.value);
  const score = answers.filter(a => a === 'Disagree').length;

  //if score is 0-3, then Dreamland Dabbler
  //if score is 4-5, then Slumber Scholar
  //if score is 6-8, then Sleep Savant
  let result = {
    title: '',
    body: '',
  };
  if (score >= 6) {
    result = {
      title: 'Sleep Savant',
      body: 'You’re ready for RISE! Your knowledge of sleep is exceptional. You understand complex sleep concepts and can dispel sleep myths easily.',
    };
  } else if (score >= 4) {
    result = {
      title: 'Slumber Scholar',
      body: "You're ready for RISE! You have a solid grasp of sleep topics and probably share helpful tips with others, though there’s room to improve with support.",
    };
  } else {
    result = {
      title: 'Dreamland Dabbler',
      body: "You're ready for RISE! You know a fair amount about sleep, but there's room for deeper understanding. You’ll succeed with the right support.",
    };
  }

  const {title, body} = result;

  useEffect(() => {
    Analytics.track('Sleep Myth Quiz Result', {
      score,
      title,
    });
  }, [score, title]);

  return (
    <RiseInterstitialTemplate
      headerText={
        <div
          style={{
            position: 'relative',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '45px',
            lineHeight: '50px',
            textAlign: 'center',
            color: '#382365',
            height: '200px',
            borderRadius: '16px',
            paddingTop: '15px',
            paddingBottom: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={img_twinkles}
            style={{position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', margin: 'auto'}}
          />
          {title}
        </div>
      }
      bodyText={
        <div style={{fontSize: '24px', marginTop: '-15px', fontWeight: 'normal', textAlign: 'center'}}>{body}</div>
      }
      continueButtonTitle="Next"
    />
  );
};

const getSleepDebtColor = (sleepDebtZone: string) => {
  switch (sleepDebtZone) {
    case 'high':
      return '#E51542';
    case 'low':
      return '#008568';
    case 'moderate':
    default:
      return '#B85C00';
  }
};

const getSleepDebtChartImage = (sleepDebtZone: string) => {
  switch (sleepDebtZone) {
    case 'high':
      return img_sleep_debt_high;
    case 'low':
      return img_sleep_debt_low;
    case 'moderate':
    default:
      return img_sleep_debt_moderate;
  }
};

const calculatedValues = [
  {
    name: 'gendered_noun',
    expression: switchExpression({
      switch: '{gender}',
      cases: {
        Male: 'man',
        Female: 'woman',
        _default: 'person',
      },
    }),
  },
  {
    name: 'gendered_noun_plural',
    expression: switchExpression({
      switch: '{gender}',
      cases: {
        Male: 'men',
        Female: 'women',
        _default: 'people',
      },
    }),
  },
  {
    name: 'gendered_noun_plural_capitalized',
    expression: switchExpression({
      switch: '{gender}',
      cases: {
        Male: 'Men',
        Female: 'Women',
        _default: 'People',
      },
    }),
  },
  {
    name: 'in_your_age_group',
    expression: switchExpression({
      switch: '{age}',
      cases: {
        '18-23': 'in your late teens or early 20s',
        '24-29': 'in your 20s',
        '30s': 'in your 30s',
        '40s': 'in your 40s',
        '50s': 'in your 50s',
        '60+': 'over 60',
        _default: '',
      },
    }),
  },
  {
    name: 'in_their_age_group',
    expression: switchExpression({
      switch: '{age}',
      cases: {
        '18-23': 'in their late teens or early 20s',
        '24-29': 'in their 20s',
        '30s': 'in their 30s',
        '40s': 'in their 40s',
        '50s': 'in their 50s',
        '60+': 'over 60',
        _default: '',
      },
    }),
  },

  {
    name: 'goal_phrase',
    expression: switchExpression({
      switch: '{goal}',
      cases: {
        'Fall asleep faster': 'fall asleep faster',
        'Stay asleep through the night': 'stay asleep all night',
        'Improve sleep quality': 'improve their sleep quality',
        'Wake up feeling refreshed': 'wake refreshed',
        'Feel more energized during the day': 'feel more energized during the day',
        _default: 'improve their sleep',
      },
    }),
  },
  {
    name: 'profile_goal_phrase',
    expression: switchExpression({
      switch: '{goal}',
      cases: {
        'Fall asleep faster': 'Fall asleep faster',
        'Stay asleep through the night': 'Stay asleep through the night',
        'Improve sleep quality': 'Improve sleep quality',
        'Wake up feeling refreshed': 'Wake up feeling refreshed',
        'Feel more energized during the day': 'Feel more energized during the day',
        _default: 'Improve sleep quality',
      },
    }),
  },
  {
    name: 'bedtime_midpoint_midnight_offset',
    expression: switchExpression({
      switch: '{bedtime_routines}',
      cases: {
        'Before 10 PM': '2.5',
        '10-11 PM': '1.5',
        '11 PM-12 AM': '0.5',
        '12-1 AM': '-0.5',
        'After 1 AM': '-1.5',
        Varied: '',
        _default: '',
      },
    }),
  },
  {
    name: 'wake_midpoint_midnight_offset',
    expression: switchExpression({
      switch: '{sleep_patterns}',
      cases: {
        'Before 6 AM': '5.5',
        '6-7 AM': '6.5',
        '7-8 AM': '7.5',
        '8-9 AM': '8.5',
        '9-10 AM': '9.5',
        'After 10 AM': '10',
        _default: '',
      },
    }),
  },
  {
    name: 'ideal_wake_midpoint_midnight_offset',
    expression: switchExpression({
      switch: '{sleep_consistency}',
      cases: {
        'Before 6 AM': '5.5',
        '6-7 AM': '6.5',
        '7-8 AM': '7.5',
        '8-9 AM': '8.5',
        '9-10 AM': '9.5',
        'After 10 AM': '10',
        _default: '',
      },
    }),
  },
];

export const survey_config: RiseSurveyConfig = {
  // Add the images to preload.  These will be preloaded when the survey is loaded.
  // this is optional.
  preloadImages: [
    img_avatar_1,
    img_avatar_2,
    img_avatar_3,
    img_avatar_4,
    img_female_hero,
    img_graph_multiple,
    img_male_hero,
    img_wearables_no,
    img_wearables_yes,
    img_nfl_nba,
    img_rise_twinkles,
    img_sleep_debt_accumulation,
    img_sleep_debt_high,
    img_sleep_debt_low,
    img_sleep_debt_moderate,
    img_sleep_debt_reduction_chart,
    img_sleep_beliefs_quiz_illustration,
    img_twinkles,
  ],
  ...survey_config_base,
  title: 'Sleep Myth Quiz Jan 10 2025',
  calculatedValues,
  enableAnswerNewStyle: true,

  pages: [
    /**
     * Category: About Me
     */
    radioPage({
      category: 'About Me',
      name: 'age',
      title: 'How old are you?',
      description: 'Sleep patterns change with age',
      choices: ['18-23', '24-29', '30s', '40s', '50s', '60+'],
      autoAdvance: true,
      isRequired: true,
    }),
    /**
     * Category: About Me
     */

    radioPage({
      category: 'About Me',
      name: 'gender',
      title: 'What gender do you identify with?',
      description: 'Both biological and societal factors influence sleep patterns',
      choices: ['Female', 'Male', 'Non-Binary', 'Prefer not to say'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'About Me',
      name: 'goal',
      title: 'What is your primary goal?',
      description: 'We’ll personalize your plan to match your goal',
      choices: randomize([
        'Fall asleep faster',
        'Stay asleep through the night',
        'Improve sleep quality',
        'Wake up feeling refreshed',
        'Feel more energized during the day',
      ]),
      autoAdvance: true,
      isRequired: true,
    }),

    interstitial({
      category: 'About Me',
      name: '4_out_of_5_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          headerText={`RISE has helped over 190k people ${surveyInstance.getVariable('goal_phrase')}`}
          heroImage={<img src={img_rise_twinkles} style={{maxHeight: '200px'}} />}
          bodyText={'<div class="text-large leading-loose">Let’s look at your sleep to see how we can help</div>'}
          continueButtonTitle="Next"
        />
      ),
    }),

    /**
     * Category: My Sleep
     */
    radioPage({
      category: 'My Sleep',
      name: 'how_long',
      title: 'How long have you struggled with sleep?',
      description: 'Many {gendered_noun_plural} {in_their_age_group} chronically struggle with sleep',
      choices: ['Weeks', 'Months', 'Years', 'Decades', 'My entire life', "Sleep isn't an issue for me"],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'My Sleep',
      name: 'bedroom_temperature',
      title: 'What is the temperature in your bedroom while sleeping?',
      description: 'Your environment can impact sleep quality',
      choices: ['Cool and comfortable', 'Warm and cozy', 'Varies throughout the night', 'Too hot or too cold'],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'My Sleep',
      name: 'noise_level',
      title: 'What is the noise level in your bedroom while sleeping?',
      description: 'Research indicates noise levels affect how well you sleep through the night',
      choices: ['Silent', 'Some background noise', 'Noisy', 'Varies'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Sleep',
      name: 'light_level',
      title: 'How much light is in your bedroom while you sleep? ',
      description: 'Light can disrupt your circadian rhythm and interfere with deep sleep',
      choices: ['Pitch black', 'Dim light', 'Some light', 'Bright light'],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'My Sleep',
      name: 'current_quality_v2',
      title: 'How would you rate your current sleep quality?',
      description: 'Sleep quality directly impacts your energy throughout the day',
      choices: ['Stellar', 'Good', 'Okay', 'Poor', 'Awful'],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'My Sleep',
      name: 'wake_up',
      title: 'How do you feel when you wake up in the morning?',
      description: 'This helps us understand your sleep quality and circadian rhythm',
      choices: [
        'Refreshed and alert',
        'Well rested but need time to get going',
        'Still tired',
        'Tired and groggy',
        'I need a crane to lift me out of bed',
      ],
      autoAdvance: true,
      isRequired: true,
    }),

    interstitial({
      category: 'My Sleep',
      name: 'sleep_debt_introduction_one_week_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_graph_multiple} style={{maxHeight: '233px'}} />}
          preHeaderText={'<div class="left" style="padding-bottom:3px;">You are not alone!</div>'}
          headerText={`<div class="left">Most ${surveyInstance.getVariable('gendered_noun_plural')} ${surveyInstance.getVariable(
            'in_their_age_group',
          )} get <span style="color:#B85C00;">7 hours</span> less sleep every week than their bodies truly need.</div>`}
          continueButtonTitle="Next"
        />
      ),
    }),

    //This will show `Male` gender
    interstitial({
      category: 'My Sleep',
      hideCategory: true,
      hideProgressBar: true,
      visibleIf: "{gender} == 'Male'",
      name: 'testimonial_male_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_male_hero} style={{maxHeight: '200px'}} />}
          headerText={`Rise supports ${surveyInstance.getVariable('gendered_noun_plural')} who have struggled with sleep for years`}
          bodyText="<div class='text-large italic'>“In only a few weeks, I found myself more focused, energetic, and productive at work.” <div class='text-medium' style='padding-top:8px;'>-Chase</div></div>"
          continueButtonTitle="Next"
        />
      ),
    }),
    //This will show for any gender not `Male`
    interstitial({
      category: 'My Sleep',
      hideCategory: true,
      hideProgressBar: true,
      visibleIf: "{gender} != 'Male'",
      name: 'testimonial_female_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_female_hero} style={{maxHeight: '200px'}} />}
          headerText={`Rise supports ${surveyInstance.getVariable('gendered_noun_plural')} who have struggled with sleep for years`}
          bodyText="<div class='text-large italic'>“I’m kinder to myself when I feel down or less energized. I know how to get my energy back when I need it.” <div class='text-medium' style='padding-top:8px;'>-Morgane</div></div>"
          continueButtonTitle="Next"
        />
      ),
    }),

    radioPage({
      category: 'My Sleep',
      name: 'bedtime_routines',
      title: 'When did you typically go to bed in the last 2 weeks?',
      description: "We'll use this to determine how much sleep your body is getting",
      choices: ['Before 10 PM', '10-11 PM', '11 PM-12 AM', '12-1 AM', 'After 1 AM', 'Varied'],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'My Sleep',
      name: 'sleep_patterns',
      title: 'When did you typically wake up in the last 2 weeks?',
      description: "We'll use this to determine how much sleep your body is getting",
      choices: ['Before 6 AM', '6-7 AM', '7-8 AM', '8-9 AM', '9-10 AM', 'After 10 AM'],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'My Sleep',
      name: 'sleep_consistency',
      title: 'If you had complete freedom, when would you ideally wake up?',
      description: "We'll use this to understand how your sleep changes based on your schedule.",
      choices: ['Before 6 AM', '6-7 AM', '7-8 AM', '8-9 AM', '9-10 AM', 'After 10 AM'],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'My Sleep',
      name: 'consistency_throughout_the_week',
      title: 'Do you have a consistent sleep schedule throughout the week?',
      description: 'Maintaining a consistent sleep schedule improves sleep quality and energy levels',
      choices: ['It varies', 'Mainly consistent, but varies on weekends', 'Consistent across weekdays & weekends'],
      autoAdvance: true,
      isRequired: true,
    }),

    interstitial({
      category: 'My Sleep',
      visibleIf: "{consistency_throughout_the_week} != 'Consistent across weekdays & weekends'",
      name: 'sleep_debt_accumulation_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          preHeaderText={`<div class="left" style="padding-bottom:3px;">This is common</div>`}
          heroImage={<img src={img_sleep_debt_accumulation} style={{maxHeight: '233px'}} />}
          headerText={`<div class="left">Most people lose sleep during the week and try to catch up on weekends, but fall short</div>`}
          bodyText={`<div class="left heading3 color-gray2" style="margin-top:-12px">Sleep Debt accumulates over time</div>`}
          continueButtonTitle="Next"
        />
      ),
    }),
    interstitial({
      category: 'My Sleep',
      name: 'sleep_debt_category_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={
            <img
              src={getSleepDebtChartImage(
                getSleepDebtZoneFromMidpointOffsets(
                  surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
                  surveyInstance.getVariable('wake_midpoint_midnight_offset'),
                ),
              )}
              style={{maxHeight: '226px'}}
            />
          }
          headerText={`<div class="left">Based on what you told us, your estimated Sleep Debt is <span style="color:${getSleepDebtColor(
            getSleepDebtZoneFromMidpointOffsets(
              surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
              surveyInstance.getVariable('wake_midpoint_midnight_offset'),
            ),
          )}">${getSleepDebtZoneFromMidpointOffsets(
            surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
            surveyInstance.getVariable('wake_midpoint_midnight_offset'),
          )}</span></div>`}
          bodyText={
            '<div class="left heading3 color-gray3"><div class="bold color-black">What is Sleep Debt?</div>Sleep Debt is the gap between the sleep your body needs and the sleep it gets</div>'
          }
          continueButtonTitle="Next"
          onClick={() => {
            surveyInstance.getQuestionsByNames(['sleep_debt_category_interstitial']).forEach(q => {
              q.value = getSleepDebtZoneFromMidpointOffsets(
                surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
                surveyInstance.getVariable('wake_midpoint_midnight_offset'),
              );
            });
          }}
        />
      ),
    }),
    interstitial({
      category: 'My Sleep',
      hideCategory: true,
      hideProgressBar: true,
      visibleIf: "{sleep_debt_category_interstitial} != 'Low'",
      name: 'success_stories_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_sleep_debt_reduction_chart} style={{maxHeight: '200px'}} />}
          headerText={`Over 190k people have reduced their Sleep Debt with RISE`}
          bodyText={`<div style="font-size:24px;">Now let's explore how your lifestyle affects your sleep and energy`}
          continueButtonTitle="Next"
        />
      ),
    }),

    /**
     * Category: My Lifestyle
     */
    radioPage({
      category: 'My Lifestyle',
      name: 'caffeine_intake',
      title: 'Do you consume coffee or caffeinated drinks?',
      description: 'Properly timing your caffeine intake can help you fall asleep faster',
      choices: ['No', '1 cup', '2 cups', '3 cups', 'More than 3 cups'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'hydration',
      title: 'How much water do you typically drink in a day?',
      description: 'Being hydrated promotes energy',
      choices: ['Fewer than 4 glasses', '4-7 glasses', '8 or more glasses', 'It varies'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'dinner_timing',
      title: 'When do you typically eat dinner?',
      description:
        'As a {gendered_noun} {in_your_age_group}, meal timing can impact your ability to fall and stay asleep',
      choices: ['Around 6 PM', 'Between 6-8 PM', 'After 8 PM'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'have_wearable',
      title: 'Do you use a smart watch or fitness tracker?',
      choices: ['Yes', 'No'],
      autoAdvance: true,
      isRequired: true,
    }),

    interstitial({
      category: 'My Lifestyle',
      hideCategory: true,
      hideProgressBar: true,
      visibleIf: "{have_wearable} == 'Yes'",
      name: 'wearables_interstitial_yes',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_wearables_yes} style={{maxHeight: '250px'}} />}
          headerText={`RISE integrates with your wearable for advanced insights`}
          bodyText={
            '<div class="text-large leading-loose" style="padding-left:22px; padding-right:22px;"><div class="bold text-x-large leading-tight">1.7 Billion</div>nights of sleep tracked</div>'
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    interstitial({
      category: 'My Lifestyle',
      hideCategory: true,
      hideProgressBar: true,
      visibleIf: "{have_wearable} != 'Yes'",
      name: 'wearables_interstitial_no',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_wearables_no} style={{maxHeight: '250px'}} />}
          headerText={`Use your phone to track sleep and get insights—no wearable needed`}
          bodyText={
            '<div class="text-large leading-loose" style="padding-left:22px; padding-right:22px;"><div class="bold text-x-large leading-tight">1.7 Billion</div>nights of sleep tracked</div>'
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'sports_activity',
      title: 'How often do you exercise or stay active?',
      description: 'Exercise regulates sleep patterns, making it easier to fall asleep and stay asleep',
      choices: ['Regularly', 'Occasionally', 'Rarely', 'It varies'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'job_type',
      title: 'What type of job do you have?',
      description: 'Your work hours, stress, and activity impacts your sleep',
      choices: randomize(['Office job', 'Physical labor', 'Remote work', 'Shift work'], ['Other']),
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'screen_time',
      title: 'How long do you spend on screens per day?',
      description: 'Blue light from screens can disrupt your circadian rhythm',
      choices: ['Less than 2 hours', '2-4 hours', '4-6 hours', 'More than 6 hours', 'Varies'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'customized_approach',
      title: 'Which best describes you?',
      description: `{gendered_noun_plural_capitalized} {in_their_age_group} need a customized approach to {goal_phrase}. We'll build your plan around your lifestyle and habits.`,
      choices: [
        'I struggle to maintain a healthy sleep schedule',
        'I have some good sleep habits',
        'My sleep routine is strong & my schedule is consistent',
      ],
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'My Lifestyle',
      name: 'debt_reduction_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<ProgressChart />}
          headerText={`<div class="left">Increase energy with RISE vs. trying on your own</div>`}
          bodyText={`More active RISE users reduce twice as much Sleep Debt as less active users`}
          continueButtonTitle="Next"
        />
      ),
    }),

    /** Start Sleep Quiz */
    interstitial({
      name: 'sleep_quiz_intro_interstitial',
      category: 'Attitudes & Beliefs',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_sleep_beliefs_quiz_illustration} style={{maxHeight: '200px'}} />}
          headerText={
            <div style={{fontWeight: 'normal', textAlign: 'left'}}>
              Finally, let’s find the best approach based on your <strong>attitudes and beliefs about sleep</strong>
            </div>
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    radioPage({
      name: 'sleep_quiz_8_hours_sleep_question',
      title: 'Everyone needs 8 hours of sleep to function well',
      choices: ['Agree', 'Disagree'],
      category: 'Attitudes & Beliefs',
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'Attitudes & Beliefs',
      name: 'sleep_quiz_8_hours_sleep_science',
      component: () => (
        <RiseInterstitialTemplate
          headerText={<div style={{textAlign: 'left'}}>What the science says</div>}
          bodyText={
            <InfoBox
              title="Sleep needs vary person to person, influenced by genetics and lifestyle."
              body="Most adults need between 7-9 hours, but the optimal amount can vary. We’ll look at your data and tell you how much sleep your body needs."
            />
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    radioPage({
      name: 'sleep_quiz_resting_question',
      title: 'Lying in bed with your eyes closed is as good as sleeping',
      choices: ['Agree', 'Disagree'],
      category: 'Attitudes & Beliefs',
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'Attitudes & Beliefs',
      name: 'sleep_quiz_resting_science',
      component: () => (
        <RiseInterstitialTemplate
          headerText={<div style={{textAlign: 'left'}}>What the science says</div>}
          bodyText={
            <InfoBox
              title="Quality sleep involves going through various stages, including deep REM sleep."
              body="Simply resting does not provide the same benefits as sleep, such as memory consolidation and the repair of bodily tissues."
            />
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    radioPage({
      name: 'sleep_quiz_try_harder_question',
      title: "If you can't sleep, you should stay in bed and try harder",
      choices: ['Agree', 'Disagree'],
      category: 'Attitudes & Beliefs',
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'Attitudes & Beliefs',
      name: 'sleep_quiz_try_harder_science',
      component: () => (
        <RiseInterstitialTemplate
          headerText={<div style={{textAlign: 'left'}}>What the science says</div>}
          bodyText={
            <InfoBox
              title="Staying in bed while awake can lead to frustration and make the bed a cue for wakefulness rather than sleep. "
              body="It’s better to leave the bedroom and do something relaxing until you feel sleepy. RISE has science backed relaxation techniques, like autogenic training to help you fall asleep. "
            />
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    radioPage({
      name: 'sleep_quiz_more_sleep_question',
      title: 'More sleep is always better',
      choices: ['Agree', 'Disagree'],
      category: 'Attitudes & Beliefs',
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'Attitudes & Beliefs',
      name: 'sleep_quiz_more_sleep_science',
      component: () => (
        <RiseInterstitialTemplate
          headerText={<div style={{textAlign: 'left'}}>What the science says</div>}
          bodyText={
            <InfoBox
              title="Too much sleep can be just as problematic as too little sleep. "
              body="Oversleeping on a regular basis is associated with health issues such as depression, heart disease, and increased risk of mortality."
            />
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    radioPage({
      name: 'sleep_quiz_weekend_sleep_question',
      title: "It's okay to skimp on sleep during the week and catch up during the weekend",
      choices: ['Agree', 'Disagree'],
      category: 'Attitudes & Beliefs',
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'Attitudes & Beliefs',
      name: 'sleep_quiz_weekend_sleep_science',
      component: () => (
        <RiseInterstitialTemplate
          headerText={<div style={{textAlign: 'left'}}>What the science says</div>}
          bodyText={
            <InfoBox
              title="Inconsistent sleep patterns can disrupt your circadian rhythm and worsen overall sleep quality."
              body="Maintaining a regular sleep schedule is crucial for optimal health."
            />
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    radioPage({
      name: 'sleep_quiz_older_adults_sleep_question',
      title: 'Older adults need much less sleep',
      choices: ['Agree', 'Disagree'],
      category: 'Attitudes & Beliefs',
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'Attitudes & Beliefs',
      name: 'sleep_quiz_older_adults_sleep_science',
      component: () => (
        <RiseInterstitialTemplate
          headerText={<div style={{textAlign: 'left'}}>What the science says</div>}
          bodyText={
            <InfoBox
              title="Older adults need about the same amount of sleep as younger adults."
              body="However, sleep patterns might change with age, and they may experience more frequent awakenings."
            />
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    radioPage({
      name: 'sleep_quiz_alcohol_sleep_question',
      title: 'Alcohol helps you sleep',
      choices: ['Agree', 'Disagree'],
      category: 'Attitudes & Beliefs',
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'Attitudes & Beliefs',
      name: 'sleep_quiz_alcohol_sleep_science',
      component: () => (
        <RiseInterstitialTemplate
          headerText={<div style={{textAlign: 'left'}}>What the science says</div>}
          bodyText={
            <InfoBox
              title="Alcohol might help you fall asleep faster but it significantly reduces the quality of sleep, particularly by reducing REM sleep, which is crucial for memory and learning."
              body="RISE can remind you when to cut off alcohol to reduce negative impact on sleep."
            />
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    radioPage({
      name: 'sleep_quiz_caffeine_sleep_question',
      title: 'Caffeine is bad for sleep',
      choices: ['Agree', 'Disagree'],
      category: 'Attitudes & Beliefs',
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'Attitudes & Beliefs',
      name: 'sleep_quiz_caffeine_sleep_science',
      component: () => (
        <RiseInterstitialTemplate
          headerText={<div style={{textAlign: 'left'}}>What the science says</div>}
          bodyText={
            <InfoBox
              title="Surprisingly, it depends on the timing. Caffeine has a half-life of about 6 hours, which means it takes about 6 hours for half of the caffeine to be eliminated from your body."
              body="To minimize caffeine’s impact on sleep, avoid caffeine at least 6 hours before bedtime. RISE can send you custom reminders based on your optimal bedtime."
            />
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    // Results
    interstitial({
      name: 'sleep_quiz_results',
      category: 'Attitudes & Beliefs',
      component: () => <ResultInterstitial />,
    }),
    /** End Sleep Myth Quiz */

    /**
     * Category: My Results
     */
    textPage({
      category: 'My Results',
      name: 'person_name',
      title: 'What is your name?',
      description: 'We’ll use this to personalize your experience',
      placeholder: 'Enter name',
      isRequired: true,
    }),
    interstitial({
      category: 'My Results',
      hideCategory: true,
      hideProgressBar: true,
      name: 'analyze_interstitial_v2',
      component: AnalyzeInterstitialV2,
    }),
    interstitial({
      category: 'My Results',
      name: 'account_creation_interstitial',
      component: AccountCreationInterstitial,
    }),
    interstitial({
      category: 'My Results',
      name: 'sleep_profile_interstitial',
      component: SleepProfileInterstitial,
    }),
    interstitial({
      category: 'My Results',
      hideCategory: true,
      hideProgressBar: true,
      name: 'sports_interstitial_v2',
      component: () => (
        <RiseInterstitialTemplate
          preHeaderText={`<div style=" font-size:12px; font-weight:900; color:#721CFF;">DID YOU KNOW?</div>`}
          heroImage={<img src={img_nfl_nba} style={{maxHeight: '226px'}} />}
          headerText={`<div>Our personalized plan is backed by top <span style="color:#721CFF;">NBA and NFL</span> athletes for peak performance</div>`}
          bodyText={`<div style="margin-top:-24px">After using Rise, <strong>97% of players</strong> reported improvements in their performance and reactions times.</div>`}
          continueButtonTitle="Next"
          onClick={() => {
            surveyInstance.getQuestionsByNames(['sleep_debt_category_interstitial']).forEach(q => {
              q.value = getSleepDebtZoneFromMidpointOffsets(
                surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
                surveyInstance.getVariable('wake_midpoint_midnight_offset'),
              );
            });
          }}
        />
      ),
    }),
  ],
};

// FOR DEV ONLY
export const qa_sleep_myth_quiz = {
  ...survey_config_base,
  title: 'QA Sleep Myth Quiz',
  calculatedValues,
  pages: [
    pickSurveyItem_UNSAFE('sleep_quiz_intro_interstitial', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_8_hours_sleep_question', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_8_hours_sleep_science', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_resting_question', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_resting_science', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_try_harder_question', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_try_harder_science', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_more_sleep_question', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_more_sleep_science', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_weekend_sleep_question', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_weekend_sleep_science', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_older_adults_sleep_question', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_older_adults_sleep_science', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_alcohol_sleep_question', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_alcohol_sleep_science', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_caffeine_sleep_question', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_caffeine_sleep_science', survey_config),
    pickSurveyItem_UNSAFE('sleep_quiz_results', survey_config),
  ],
};

export default survey_config;
