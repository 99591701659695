import {FullScreenLoadingSpinner} from 'components/FullScreenLoadingSpinner/FullScreenLoadingSpinner';
import {Duration} from 'date-fns';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {AccountCreation} from 'screens/AccountCreationScreen/AccountCreationScreen';
import {useSessionStorage} from 'usehooks-ts';
import {getEmailFromSurveyData} from 'utils/getEmailFromSurveyData';
import {useCheckoutScreenAnalytics} from 'utils/useCheckoutScreenAnalytics';
import {createAccount} from '../../externalAPIs/hypnos';
import Analytics from '../../utils/Analytics';
import {getUserCountry} from '../../utils/locale';
import {monthPlan, PlanInfo, quarterPlan, weekPlan} from '../../utils/stripePlans';
import {useEffectOnce} from '../../utils/useEffectOnce';
import {BlackButton} from './components/Buttons/BlackButton';
import {CommunityInvitation} from './components/CommunityInvitation/CommunityInvitation';
import {EditorsChoice} from './components/EditorsChoice/EditorsChoice';
import {Header} from './components/Header/Header';
import {MainOffer} from './components/MainOffer/MainOffer';
import {MoneyBackGuarantee} from './components/MoneyBackGuarantee/MoneyBackGuarantee';
import {PaymentElement} from './components/PaymentElement/PaymentElement';
import {PlanSelection} from './components/PlanSelection/PlanSelection';
import {PricingOptions} from './components/PricingOptions/PricingOptions';
import {RiseFeatured} from './components/RiseFeatured/RiseFeatured';
import {RisePromise} from './components/RisePromise/RisePromise';
import {SafeCheckout} from './components/SafeCheckout/SafeCheckout';
import {SavedDiscountBanner} from './components/SavedDiscountBanner/SavedDiscountBanner';
import {Testimonials} from './components/Testimonials/Testimonials';
import {YouGetFeatures} from './components/YouGetFeatures/YouGetFeatures';
import styles from './RunwayerCheckoutScreen.module.css';
type CheckoutScreenProps = {
  variantName: string;
};

const formatDuration = (duration: Duration) => {
  const minutes = duration.minutes?.toString().padStart(2, '0') ?? '00';
  const seconds = duration.seconds?.toString().padStart(2, '0') ?? '00';
  return `${minutes}:${seconds}`;
};

export default function RunwayerCheckoutScreen({variantName}: CheckoutScreenProps) {
  const {
    countryCode: country,
    userLanguages,
    timezone,
    timezoneStrategy,
    countryByTimezone,
    localeToFormatCurrency,
  } = getUserCountry();

  const [showBanner, setShowBanner] = useState(false);
  const timerDuration = 5 * 60 * 1000;
  const [timedOut, setTimedOut] = useState(false);
  const [timeLeft, setTimeLeft] = useState(timerDuration);
  const [subscription, setSubscription] = useSessionStorage('subscription', '');
  const [personId, setPersonId] = useSessionStorage<number | null>('personId', null);
  const [showAccountCreation, setShowAccountCreation] = useState(false);
  const [planToPurchase, setPlanToPurchase] = useState<PlanInfo>(monthPlan[country]);
  const paymentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);

  useEffectOnce(() => {
    Analytics.track('Country Code Determined', {
      country,
      userLanguages,
      timezone,
      timezoneStrategy,
      countryByTimezone,
      localeToFormatCurrency,
    });
  });

  useCheckoutScreenAnalytics(variantName);

  useEffect(() => {
    Analytics.track('Plan Selected', {
      ...planToPurchase,
    });
  }, [planToPurchase]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setShowBanner(true);
      } else {
        setShowBanner(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        if (prevTimeLeft <= 1000) {
          setTimedOut(true);
          clearInterval(interval);
          return 0;
        }
        return prevTimeLeft - 1000;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleSubscriptionSuccess = useCallback(
    async (subscription: string) => {
      const email = getEmailFromSurveyData();
      if (email) {
        setLoading(true);
        setLoadingMessage('Creating account...');
        const {person_id, error} = await createAccount(email, subscription);
        setSubscription(subscription);
        if (person_id) {
          setPersonId(person_id);
          Analytics.identify(person_id, {email});
          navigate(`/account-ready?email=${encodeURIComponent(email)}`);
        } else {
          console.error('FOUND ERROR CREATING ACCOUNT', error);
          Analytics.track('Error Creating Account - Falling through to Account Creation screen', {error});
          // TODO: Handle error?
          // Will fall through to Account Creation screen if error
        }
      } else {
        setSubscription(subscription);
      }
    },
    [setSubscription, navigate, setPersonId],
  );

  // Handling routing
  useEffect(() => {
    if (subscription && personId) {
      const email = getEmailFromSurveyData();
      navigate(`/account-ready?email=${encodeURIComponent(email)}`);
      setShowAccountCreation(false);
    } else if (subscription) {
      setShowAccountCreation(true);
    } else {
      setShowAccountCreation(false);
    }
  }, [subscription, personId, navigate]);

  if (showAccountCreation) {
    return <AccountCreation />;
  }

  const onGetMyPlanClick = () => {
    Analytics.track('Get My Plan Clicked');
    window.scrollTo({top: paymentRef.current?.offsetTop, behavior: 'smooth'});
  };

  return (
    <div className={styles.container} data-testid="screen-checkout">
      <Header />
      {loading ? <FullScreenLoadingSpinner message={loadingMessage} /> : null}
      <MainOffer />
      <PricingOptions timeLeft={timeLeft} timedOut={timedOut} formatDuration={formatDuration} />
      <PlanSelection
        selectedPlan={planToPurchase}
        onPriceSelection={plan => setPlanToPurchase(plan)}
        onButtonClick={onGetMyPlanClick}
        weekPlan={weekPlan}
        monthPlan={monthPlan}
        quarterPlan={quarterPlan}
      />
      <EditorsChoice />
      <SafeCheckout />
      <Testimonials />
      <BlackButton title="Get my plan" onClick={onGetMyPlanClick} />
      <CommunityInvitation />
      <MoneyBackGuarantee />
      <RiseFeatured />
      <RisePromise onButtonClick={onGetMyPlanClick} />
      <YouGetFeatures selectedPlan={planToPurchase} />
      <div ref={paymentRef} style={{marginBottom: '10rem'}}>
        <PaymentElement
          onSuccess={handleSubscriptionSuccess}
          planToPurchase={planToPurchase}
          onLoadingChanged={isLoading => {
            if (isLoading) {
              setLoadingMessage('Processing payment...');
            }
            setLoading(isLoading);
          }}
        />
      </div>
      <div className={[styles.savedDiscountBannerContainer, showBanner ? styles.show : styles.hide].join(' ')}>
        <SavedDiscountBanner onButtonClick={onGetMyPlanClick} />
      </div>
    </div>
  );
}
