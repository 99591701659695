import checkmark from './assets/checkmark.svg';
import styles from './MainOffer.module.css';

export const MainOffer = () => (
  <section className={styles.container}>
    <h2 className={styles.title}>Your Personalized Plan is Ready</h2>
    <div className={styles.offerItems}>
      <div className={styles.offerItem}>
        <img src={checkmark} alt="Checkmark" />
        <div>Optimized sleep insights for you, balancing life & rest</div>
      </div>
      <div className={styles.offerItem}>
        <img src={checkmark} alt="Checkmark" />
        <div>Strategies to fall asleep faster, aiming for morning freshness</div>
      </div>
      <div className={styles.offerItem}>
        <img src={checkmark} alt="Checkmark" />
        <div>Personalized bedtime routine for deeper, uninterrupted sleep</div>
      </div>
    </div>
  </section>
);
