import {BlackButton} from '../Buttons/BlackButton';
import styles from './RisePromise.module.css';

export const RisePromise = ({onButtonClick}: {onButtonClick: () => void}) => {
  return (
    <section className={styles.risePromise}>
      <h3>Our Promise</h3>
      <div>
        Elevate your sleep with Rise, the advanced sleep tracker designed to help you understand and improve your sleep
        patterns. We understand the importance of quality rest, and Rise is here to provide you with the insights and
        tools you need for better sleep. With Rise, you can effortlessly track your sleep cycles, monitor sleep quality,
        and receive personalized recommendations to optimize your sleep routine. Our app utilizes cutting-edge
        technology to empower you to make informed decisions about your sleep habits. Join a community of individuals
        prioritizing their sleep health and well-being. Rise isn't just a sleep tracker; it's your partner in achieving
        restful nights and energized days.
        <br />
        <br />
      </div>

      <BlackButton title="Get my plan" onClick={onButtonClick} />
    </section>
  );
};
