import Environment from '../utils/Environment';
import {PaymentRequestPaymentMethodEvent, Stripe, StripeElements} from '@stripe/stripe-js';
import {validateCreditCard} from './stripe';
import Analytics from '../utils/Analytics';
import ReactPixel from 'react-facebook-pixel';
import * as Sentry from '@sentry/react';
import {PlanInfo} from '../utils/stripePlans';
import validateEmail from '../utils/validateEmail';
import {getUserCountry} from '../utils/locale';

export const BASE_URL = 'https://api.risesci.com';

// This defaults to "production"
// the issue is that if this is misconfigured on production and test is the default, then users will get Rise for free
const environment = Environment.getVar('REACT_APP_ENV') ?? 'production';

export const createCheckoutDetails = async (): Promise<{client_secret: string | undefined}> => {
  const endpoint = `${BASE_URL}/api/stripe-setup-checkout`;

  try {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        env: environment,
      }),
    });
    return await response.json();
  } catch (error) {
    Sentry.captureException(error);
    Analytics.track('Stripe Setup Checkout Error', {error});
    return {client_secret: undefined};
  }
};

type CheckoutResponse = {
  subscription?: string;
  error?: string;
  detailed_message?: string;
  code?: string;
  type?: string;
  decline_code?: string;
  advice_code?: string;
};

const checkoutWithHypnos = async (data: {
  env: string;
  payment_method_id: string;
  code: string;
  email?: string;
}): Promise<CheckoutResponse> => {
  try {
    const endpoint = `${BASE_URL}/api/stripe-dynamic-checkout`;

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    // fetch API does not error on non-ok responses (differs from axios)
    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });
    return (await response.json()) as CheckoutResponse;
  } catch (error) {
    if (error instanceof Error) {
      return {
        error: 'Fetch Error',
        detailed_message: error.message,
      };
    } else {
      return {
        error: 'Fetch Error',
        detailed_message: 'Not provided',
      };
    }
  }
};

export const completeCheckout = async (
  plan: PlanInfo,
  payment_method_id: string,
  email: string | undefined = undefined,
  paymentMethod: string,
): Promise<{subscription: string | null; error: string | null}> => {
  const {countryCode: supportedCountry, countryByTimezone} = getUserCountry();

  try {
    Analytics.track('Stripe Checkout Started', {
      ...plan,
      payment_method_id,
      paymentMethod,
      supportedCountry,
      countryByTimezone,
    });

    const body: {env: string; payment_method_id: string; code: string; email?: string} = {
      env: environment,
      payment_method_id,
      code: plan.id,
    };

    if (email) {
      Analytics.track('Platform Pay Email Captured', {email});
      body.email = email;
    }

    const checkoutResponse = await checkoutWithHypnos(body);

    if (checkoutResponse.subscription) {
      Analytics.track('Stripe Checkout Success', {
        subscription: checkoutResponse.subscription,
        supportedCountry,
        countryByTimezone,
        paymentMethod,
        payment_method_id,
        ...plan,
      });
      return {error: null, subscription: checkoutResponse.subscription};
    } else {
      const {error, detailed_message, code, type, decline_code, advice_code} = checkoutResponse;

      Analytics.track('Stripe Checkout Failure', {
        error: error ?? 'Unspecified Error',
        detailed_message: String(detailed_message),
        code: String(code),
        type: String(type),
        decline_code: String(decline_code),
        advice_code: String(advice_code),
        supportedCountry,
        countryByTimezone,
        paymentMethod,
        payment_method_id,
        ...plan,
      });
      return {error: 'Could not complete checkout. Please try again or use an alternate method.', subscription: null};
    }
  } catch (error) {
    Sentry.captureException(error);
    Analytics.track('Stripe Checkout Failure', {
      error: 'Unexpected Error',
      supportedCountry,
      countryByTimezone,
      paymentMethod,
    });
    return {error: 'Could not complete checkout. Please try again or use an alternate method.', subscription: null};
  }
};

const reportMarketingPurchaseEvent = (plan: PlanInfo) => {
  Analytics.track('PaymentAccepted', {
    product_id: plan.product,
  });
  ReactPixel.track('Purchase', {currency: plan.currency.toUpperCase(), value: plan.introPrice});

  try {
    dataLayer.push({event: 'conversion', value: plan.introPrice, currency: plan.currency.toUpperCase()});
  } catch (e) {
    // Silently handle missing dataLayer
  }
};

/**
 * Perform a payment transaction with a credit card. Will return the id of a subscription to be used in the
 * account creation step or a user-facing error message.
 *
 * @async
 * @param {PlanInfo} plan - The plan associated with the payment.
 * @param {StripeElements} elements - The Stripe elements for card input.
 * @param {Stripe} stripe - The Stripe object for handling payments.
 * @param {string} clientSecret - The client secret associated with the transaction.
 * @returns {Promise<{subscription: string | null; error: string | null}>} - The result of the payment transaction, including the subscription ID and any user-facing error message.
 */
export const payWithCreditCard = async (
  plan: PlanInfo,
  elements: StripeElements | null,
  stripe: Stripe | null,
  clientSecret: string | undefined,
): Promise<{subscription: string | null; error: string | null}> => {
  const {countryCode: supportedCountry, countryByTimezone} = getUserCountry();

  Analytics.track('Credit Card Payment Started', {...plan, supportedCountry, countryByTimezone});
  const {result, error} = await validateCreditCard(clientSecret, stripe, elements);

  if (result?.setupIntent?.payment_method) {
    const {subscription, error} = await completeCheckout(
      plan,
      result.setupIntent.payment_method as string,
      undefined,
      'credit_card',
    );
    if (subscription) {
      Analytics.track(`Credit Card Success`, {...plan, supportedCountry, countryByTimezone});
      reportMarketingPurchaseEvent(plan);
      return {subscription, error: null};
    } else {
      Analytics.track(`Credit Card Failure`, {error, ...plan, supportedCountry, countryByTimezone});
      return {subscription: null, error: 'Could not complete checkout, please try again.'};
    }
  }

  if (!error) {
    // if error is present, Analytics will be reported in validateCreditCard method
    Analytics.track(`Credit Card Failure`, {
      error: 'payment_method missing',
      ...plan,
      supportedCountry,
      countryByTimezone,
    });
  }

  return {subscription: null, error: error ?? 'Could not complete checkout, please try again.'};
};

/**
 * Performs a payment using the platform pay method. Will return the id of a subscription to be used in the
 * account creation step or a user-facing error message.
 *
 * @param {PlanInfo} plan - The payment priceId.
 * @param {PaymentRequestPaymentMethodEvent} event - The payment method event object.
 * @returns {Promise<{subscription: Object|null, error: string|null}>} - The result of the payment.
 */
export const payWithPlatformPay = async (
  plan: PlanInfo,
  event: PaymentRequestPaymentMethodEvent,
): Promise<{subscription: string | null; error: string | null}> => {
  const {countryCode: supportedCountry, countryByTimezone} = getUserCountry();

  Analytics.track('Platform Pay Payment Started', {
    store: event.walletName,
    ...event,
    ...plan,
    supportedCountry,
    countryByTimezone,
  });

  const {paymentMethod, payerEmail} = event;

  const {subscription, error} = await completeCheckout(plan, paymentMethod.id, payerEmail, event.walletName);

  if (subscription) {
    Analytics.track('Platform Pay Success', {
      store: event.walletName,
      ...event,
      ...plan,
      supportedCountry,
      countryByTimezone,
    });
    reportMarketingPurchaseEvent(plan);
    return {subscription, error: null};
  } else {
    Analytics.track('Platform Pay Failure', {
      error,
      store: event.walletName,
      ...event,
      ...plan,
      supportedCountry,
      countryByTimezone,
    });
    return {subscription: null, error: 'Could not complete checkout, please try again.'};
  }
};

export const createAccount = async (
  email: string,
  subscription: string,
  flow = 'web-acquisition',
): Promise<{person_id: number | null; error: boolean}> => {
  const endpoint = `${BASE_URL}/api/runwayer-create-account`;

  Analytics.track('Account Creation Started', {flow, email, subscription});

  try {
    if (!email) {
      throw new Error('Email is required');
    }

    if (!validateEmail(email)) {
      throw new Error('Email is invalid');
    }
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        environment,
        flow,
        email,
        subscriptionId: subscription,
      }),
    });
    const responsePayload = await response.json();

    if (responsePayload.error && responsePayload.error.length) {
      throw new Error(responsePayload.error);
    }

    if (response.status !== 200) {
      throw new Error('Failed to create account');
    }
    const {userId} = responsePayload;

    Analytics.identify(userId);
    Analytics.track('Account Creation Success', {email, subscription});
    return {person_id: userId, error: false};
  } catch (error) {
    Analytics.track('Account Creation Failure', {error, email, subscription});
    return {person_id: null, error: true};
  }
};

/**
 * Create an account with only an email address.
 *
 * @param email - The email address to create an account for.
 * @returns The result of the account creation.
 */
export const createAccountPreSubscription = async (
  email: string,
  flow = 'web-acquisition',
): Promise<{person_id: number; error: false} | {personId: null; error: true; errorMessage: string}> => {
  const endpoint = `${BASE_URL}/api/web-acquisition/create-account-pre-subscription`;

  Analytics.track('Pre Subscription Account Creation Started', {flow, email});

  try {
    if (!email) {
      throw new Error('Email is required');
    }

    if (!validateEmail(email)) {
      throw new Error('Email is invalid');
    }
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const response = await fetch(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        environment,
        flow,
        email,
      }),
    });
    const responsePayload = await response.json();

    if (responsePayload.error && responsePayload.error.length) {
      throw new Error(responsePayload.error);
    }

    if (response.status !== 200) {
      throw new Error('Failed to create account');
    }
    const {userId} = responsePayload;

    Analytics.identify(userId);
    Analytics.track('Pre Subscription Account Creation Success', {email});
    return {person_id: userId, error: false};
  } catch (error) {
    Analytics.track('Pre Subscription Account Creation Failure', {error, email});
    return {personId: null, error: true, errorMessage: (error as Error).message};
  }
};
