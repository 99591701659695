import {useGrowthBook} from '@growthbook/growthbook-react';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Environment from 'utils/Environment';
import QA, {useExperimentQAHydration} from './QA';
import Analytics from 'utils/Analytics';
import {SupportedCountryCode} from 'utils/stripePlans';

const QABarContent = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const growthbook = useGrowthBook();
  const [showGB, setShowGB] = useState(false);

  useExperimentQAHydration();

  const [countryCode, setCountryCode] = useState<SupportedCountryCode | null>(
    localStorage.getItem('qa_countryCode') as SupportedCountryCode | null,
  );

  const handleCountryCode = (countryCode: SupportedCountryCode | null) => {
    if (countryCode) {
      setCountryCode(countryCode);
      localStorage.setItem('qa_countryCode', countryCode);
    } else {
      setCountryCode(null);
      localStorage.removeItem('qa_countryCode');
    }
    window.location.reload();
  };
  useEffect(() => {
    if (show) {
      Analytics.track('Admin Bar Shown');
    }
  }, [show]);

  if (!show) {
    return (
      <div style={{...styles.container, ...{backgroundColor: 'transparent', boxShadow: 'none'}}}>
        <div onClick={() => setShow(true)} style={{...styles.button, alignSelf: 'flex-start'}}>
          QA
        </div>
      </div>
    );
  }
  const features = Object.keys(growthbook.getFeatures()).map(key => ({
    key,
    value: growthbook.evalFeature(key).value,
    override: growthbook.evalFeature(key).source === 'override',
  }));

  return (
    <div style={styles.container}>
      <div onClick={() => setShow(false)} style={{...styles.button, ...{backgroundColor: 'black', color: 'white'}}}>
        X
      </div>
      <div>
        <div style={{display: 'flex', flexDirection: 'row', gap: '4px'}}>
          <div
            onClick={() => {
              navigate('/devqatools');
              setShow(false);
            }}
            style={{...styles.button, ...{backgroundColor: 'purple'}}}
          >
            Admin
          </div>
          <div
            onClick={() => {
              setShowGB(!showGB);
            }}
            style={{...styles.button, ...{backgroundColor: 'purple'}}}
          >
            GB
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', gap: '4px'}}>
          <div
            style={{...styles.feature, ...(countryCode === 'US' ? styles.enabled : styles.disabled)}}
            onClick={() => handleCountryCode('US')}
          >
            US
          </div>
          <div
            style={{...styles.feature, ...(countryCode === 'CA' ? styles.enabled : styles.disabled)}}
            onClick={() => handleCountryCode('CA')}
          >
            CA
          </div>
          <div
            style={{...styles.feature, ...(countryCode === 'GB' ? styles.enabled : styles.disabled)}}
            onClick={() => handleCountryCode('GB')}
          >
            GB
          </div>
          <div
            style={{...styles.feature, ...(countryCode === 'AU' ? styles.enabled : styles.disabled)}}
            onClick={() => handleCountryCode('AU')}
          >
            AU
          </div>
          <div
            style={{...styles.feature, ...(countryCode === null ? styles.enabled : styles.disabled)}}
            onClick={() => handleCountryCode(null)}
          >
            X
          </div>
        </div>
      </div>
      {showGB ? (
        <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
          {features
            .sort((a, b) => a.key.localeCompare(b.key))
            .map(({key, value, override}) => (
              <div
                key={key}
                style={{...styles.feature, ...(value ? styles.enabled : styles.disabled)}}
                onClick={() => {
                  QA.forceFeature(key, !value);
                }}
              >
                {key}: {String(value)}
                {override ? <div style={styles.override}>*</div> : null}
              </div>
            ))}
        </div>
      ) : null}
    </div>
  );
};

export const QABar = () => {
  if (Environment.isTest) {
    return <QABarContent />;
  }
  return null;
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,

    padding: '4px',
    display: 'flex',
    // flexDirection: 'row',
    gap: '4px',
    fontSize: '8px',
    flexWrap: 'wrap',
    userSelect: 'none',
    zIndex: 1000,

    backgroundColor: '#eee',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.8)',
    paddingBottom: '8px',
  },
  feature: {
    display: 'flex',
    backgroundColor: '#ddd',
    padding: '2px 4px',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#fff',
    alignItems: 'center',
    position: 'relative',
    height: '20px',
    gap: '4px',
  },
  enabled: {
    backgroundColor: '#0ea45e',
  },
  disabled: {
    backgroundColor: '#c34531',
  },
  button: {
    fontSize: '12px',
    backgroundColor: '#ddd',
    padding: '2px 4px',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#fff',
    minWidth: '20px',
    height: '20px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
  override: {
    fontSize: '20px',
    color: 'yellow',
    fontWeight: '900',
    lineHeight: '12px',
  },
};
