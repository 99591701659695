import {useEffect, useState} from 'react';
import styles from './ScaleBar.module.css';
import criticalIcon from './alert.svg';

const FADE_IN_DURATION = 300;
const BAR_FILL_DURATION = 700;
const DELTA_FADE_IN_DURATION = 500;

type Props = {
  title: string;
  currentValue: number;
  futureValue: number;
  delta: number;
  showCritical: boolean;
};

const CriticalIcon = () => <img src={criticalIcon} className={[styles.pip, styles.criticalIcon].join(' ')} />;
const OldValuePip = () => <div className={[styles.pip, styles.oldValuePip].join(' ')} />;
const NewValuePip = () => <div className={[styles.pip, styles.newValuePip].join(' ')} />;
const Delta = ({delta, visible}: {delta: number; visible: boolean}) => {
  if (!visible) return null;
  return (
    <div className={styles.delta} style={{animationDuration: `${DELTA_FADE_IN_DURATION}ms`}}>
      +{Math.round(delta * 100)}%
    </div>
  );
};
const Bar = ({
  color,
  value,
  startingValue = 0,
  style,
  PipComponent,
}: {
  color: string;
  value: number;
  startingValue?: number;
  style?: React.CSSProperties;
  PipComponent?: React.ReactNode;
}) => {
  const width = Math.max(startingValue, value - startingValue);
  return (
    <div className={styles.bar} style={{left: `${startingValue}%`, width: `${width}%`, ...style}}>
      <div className={styles.barInner} style={{width: `100%`, backgroundColor: color}} />
      {PipComponent ? <div className={styles.pipContainer}>{PipComponent}</div> : null}
    </div>
  );
};

export default function ScaleBar({title, currentValue, futureValue, delta, showCritical}: Props) {
  const [showFuture, setShowFuture] = useState(false);
  const [showDelta, setShowDelta] = useState(false);
  const current = currentValue * 100;
  const future = futureValue * 100;
  useEffect(() => {
    const timeout1 = setTimeout(() => {
      setShowFuture(true);
    }, FADE_IN_DURATION);
    const timeout2 = setTimeout(() => {
      setShowDelta(true);
    }, FADE_IN_DURATION + BAR_FILL_DURATION);
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []);
  return (
    <div className={styles.container} style={{animationDuration: `${FADE_IN_DURATION}ms`}}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        <Delta delta={delta} visible={showDelta} />
      </div>
      <div className={styles.barContainer}>
        <Bar color={'#e0e0e0'} value={100} />
        <Bar
          color={'#721cff'}
          startingValue={0}
          value={showFuture ? future : current}
          style={{transitionDuration: `${BAR_FILL_DURATION}ms`}}
          PipComponent={showFuture ? <NewValuePip /> : null}
        />

        {showCritical ? (
          <Bar color={'#ff9900'} value={current} PipComponent={<CriticalIcon />} />
        ) : (
          <Bar color={'#382365'} value={current} PipComponent={<OldValuePip />} />
        )}
      </div>
    </div>
  );
}
