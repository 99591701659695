export enum SleepQualityLevel {
  Level1 = 0.15,
  Level2 = 0.26,
  Level3 = 0.43,
  Level4 = 0.64,
  Level5 = 0.82,
  Level6 = 0.96,
}

export enum MorningWakefulnessLevel {
  Level1 = 0.13,
  Level2 = 0.23,
  Level3 = 0.45,
  Level4 = 0.68,
  Level5 = 0.77,
  Level6 = 0.84,
}

export enum DaytimeEnergyLevel {
  Level1 = 0.1,
  Level2 = 0.28,
  Level3 = 0.41,
  Level4 = 0.65,
  Level5 = 0.81,
  Level6 = 0.87,
}

export enum CircadianAlignmentLevel {
  Level1 = 0.14,
  Level2 = 0.26,
  Level3 = 0.39,
  Level4 = 0.63,
  Level5 = 0.76,
  Level6 = 0.81,
}

export const sleepQualityMap = (surveyValue: string) => {
  const map: Record<string, {start: SleepQualityLevel; end: SleepQualityLevel; level: number}> = {
    awful: {
      start: SleepQualityLevel.Level1,
      end: SleepQualityLevel.Level3,
      level: 1,
    },
    poor: {
      start: SleepQualityLevel.Level2,
      end: SleepQualityLevel.Level4,
      level: 2,
    },
    okay: {
      start: SleepQualityLevel.Level3,
      end: SleepQualityLevel.Level4,
      level: 3,
    },
    good: {
      start: SleepQualityLevel.Level4,
      end: SleepQualityLevel.Level5,
      level: 4,
    },
    stellar: {
      start: SleepQualityLevel.Level5,
      end: SleepQualityLevel.Level6,
      level: 5,
    },
  };
  const value = map[surveyValue?.toLowerCase()];
  if (!value) {
    console.error('Missing sleep quality mapping', surveyValue);
  }
  return value ?? map.okay;
};

export const morningWakefulnessMap = (surveyValue: string) => {
  const map: Record<string, {start: MorningWakefulnessLevel; end: MorningWakefulnessLevel; level: number}> = {
    'refreshed and alert': {
      start: MorningWakefulnessLevel.Level5,
      end: MorningWakefulnessLevel.Level6,
      level: 5,
    },
    'well rested but need time to get going': {
      start: MorningWakefulnessLevel.Level4,
      end: MorningWakefulnessLevel.Level5,
      level: 4,
    },
    'still tired': {
      start: MorningWakefulnessLevel.Level3,
      end: MorningWakefulnessLevel.Level4,
      level: 3,
    },
    'tired and groggy': {
      start: MorningWakefulnessLevel.Level2,
      end: MorningWakefulnessLevel.Level4,
      level: 2,
    },
    'i need a crane to lift me out of bed': {
      start: MorningWakefulnessLevel.Level1,
      end: MorningWakefulnessLevel.Level3,
      level: 1,
    },
  };
  const value = map[surveyValue?.toLowerCase()];
  if (!value) {
    console.error('Missing morning wakefulness mapping', surveyValue);
  }
  return value ?? map['still tired'];
};

export const daytimeEnergyMap = (surveyValue = 'fluctuates throughout the day') => {
  const map: Record<string, {start: DaytimeEnergyLevel; end: DaytimeEnergyLevel; level: number}> = {
    'energized all day': {
      start: DaytimeEnergyLevel.Level5,
      end: DaytimeEnergyLevel.Level6,
      level: 5,
    },
    'mainly energized': {
      start: DaytimeEnergyLevel.Level4,
      end: DaytimeEnergyLevel.Level5,
      level: 4,
    },
    'fluctuates throughout the day': {
      start: DaytimeEnergyLevel.Level3,
      end: DaytimeEnergyLevel.Level4,
      level: 3,
    },
    'mainly tired': {
      start: DaytimeEnergyLevel.Level2,
      end: DaytimeEnergyLevel.Level4,
      level: 2,
    },
    'exhausted all day': {
      start: DaytimeEnergyLevel.Level1,
      end: DaytimeEnergyLevel.Level4,
      level: 1,
    },
  };
  const value = map[surveyValue?.toLowerCase()];
  if (!value) {
    console.error('Missing daytime energy mapping', surveyValue);
  }
  return value ?? map['fluctuates throughout the day'];
};

export const circadianAlignmentMap = (sleep_patterns: string, sleep_consistency: string) => {
  const values = ['Before 6 AM', '6-7 AM', '7-8 AM', '8-9 AM', '9-10 AM', 'After 10 AM'].map(v => v.toLowerCase());

  const patternsIndex = values.indexOf(sleep_patterns?.toLowerCase());
  const consistencyIndex = values.indexOf(sleep_consistency?.toLowerCase());
  const invalidInput = patternsIndex === -1 || consistencyIndex === -1;
  const delta = invalidInput ? 2 : Math.min(Math.abs(patternsIndex - consistencyIndex), 4);

  const map: Record<string, {start: CircadianAlignmentLevel; end: CircadianAlignmentLevel; level: number}> = {
    0: {
      start: CircadianAlignmentLevel.Level5,
      end: CircadianAlignmentLevel.Level6,
      level: 5,
    },
    1: {
      start: CircadianAlignmentLevel.Level4,
      end: CircadianAlignmentLevel.Level5,
      level: 4,
    },
    2: {
      start: CircadianAlignmentLevel.Level3,
      end: CircadianAlignmentLevel.Level4,
      level: 3,
    },
    3: {
      start: CircadianAlignmentLevel.Level2,
      end: CircadianAlignmentLevel.Level4,
      level: 2,
    },
    4: {
      start: CircadianAlignmentLevel.Level1,
      end: CircadianAlignmentLevel.Level3,
      level: 1,
    },
  };
  const value = map[delta?.toString()];
  if (!value || invalidInput) {
    console.error('Missing circadian alignment mapping', delta);
  }
  return value ?? map[2];
};
