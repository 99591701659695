import {createAccount} from 'externalAPIs/hypnos';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSessionStorage} from 'usehooks-ts';
import Analytics from 'utils/Analytics';
import styles from './AccountCreationScreen.module.css';
import person_1 from './assets/person-1.webp';
import person_2 from './assets/person-2.webp';
import person_3 from './assets/person-3.webp';
import star_bulletpoint from './assets/star-bulletpoint.svg';
import validateEmail from 'utils/validateEmail';
import {FullScreenLoadingSpinner} from 'components/FullScreenLoadingSpinner/FullScreenLoadingSpinner';

export const AccountCreation = () => {
  const [subscription] = useSessionStorage('subscription', '');
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const signUp = async (event: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(event.target as HTMLFormElement);
    const email = Object.fromEntries(formData.entries()).email as string;
    if (!email) {
      setError('Please enter your email address');
      return;
    }
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    setError('');
    setLoading(true);
    const {person_id} = await createAccount(email, subscription);
    if (person_id) {
      Analytics.identify(person_id, {email});
      navigate(`/account-ready?email=${encodeURIComponent(email)}`);
    } else {
      setError('Could not register your account. Please try again.');
    }
    setLoading(false);
  };

  useEffect(() => {
    Analytics.track('Account Creation Post Paywall Shown');
  }, []);

  return (
    <div className={styles.accountCreationContainer} data-testid="screen-account-creation">
      {loading ? <FullScreenLoadingSpinner /> : null}
      <div>
        <form
          className={styles.accountCreationForm}
          onSubmit={async event => {
            event.preventDefault();
            await signUp(event);
          }}
        >
          <div>
            <h2>Thank you for your purchase! Let's create your account!</h2>
            <div className={styles.paragraphCentered}>Enter the email where you'd like us to grant access.</div>
          </div>
          <div className={styles.emailInputContainer}>
            <div className={styles.emailInputContainer2}>
              <label htmlFor="email" className={styles.emailLabel}>
                Email
              </label>
              <input id="email" type="email" className={styles.emailInput} placeholder="Email" name="email" />
            </div>

            <button className={styles.emailSubmit}>Submit</button>
            {error ? (
              <p data-testid="account-creation-error" style={{color: 'red'}}>
                {error}
              </p>
            ) : null}
          </div>
          <div className={styles.usersContainer}>
            <div className={styles.personContainer}>
              <div className={styles.imageContainer}>
                <img className={styles.person} src={person_1} alt="Person 1" />
              </div>
              <div className={styles.imageContainer}>
                <img className={styles.person} src={person_2} alt="Person 2" />
              </div>
              <div className={styles.imageContainer}>
                <img className={styles.person} src={person_3} alt="Person 3" />
              </div>
            </div>
            <div className={styles.users}>
              <span className={styles.usersNumber}>2,534,354</span> <span>users sleep better with our tracker!</span>
            </div>
          </div>
          <div className={styles.bullets}>
            <div className={styles.bulletPoint}>
              <img src={star_bulletpoint} alt="Bulletpoint" />
              <span className={styles.paragraph}>Boost your daily energy</span>
            </div>
            <div className={styles.bulletPoint}>
              <img src={star_bulletpoint} alt="Bulletpoint" />
              <span className={styles.paragraph}>Customized Sleep Improvement Plans</span>
            </div>
            <div className={styles.bulletPoint}>
              <img src={star_bulletpoint} alt="Bulletpoint" />
              <span className={styles.paragraph}>Smart Sleep Analysis</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
