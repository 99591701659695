import RiseSurvey from 'screens/RiseSurvey/RiseSurvey';
import config_control from 'surveys/control_config';
import {useFeature} from '@growthbook/growthbook-react';
import config_variant from './ex02_plan_teaser';
export const controlSurveyConfig = config_control;
export const experimentSurveyConfig = config_variant;

export const SurveyContainer = () => {
  const inExperiment = useFeature('web_ex02_plan_teaser');
  const randomSurveyConfig = inExperiment.value ? experimentSurveyConfig : controlSurveyConfig;
  return <RiseSurvey surveyConfigJson={randomSurveyConfig} />;
};
