import loading_spinner from './loading_spinner.svg';
import styles from './FullScreenLoadingSpinner.module.css';

export const FullScreenLoadingSpinner = ({message}: {message?: string}) => (
  <div className={styles.loading}>
    <img className={styles.checkoutSpinner} src={loading_spinner} alt="" />
    {message ? <div className={styles.message}>{message}</div> : null}
    <span className="screen-reader-only">Loading...</span>
  </div>
);
